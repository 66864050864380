import React from 'react';
import { Modal } from '@redq/reuse-modal';
import ModalWrapper from './modal.style';

const ModalContainer = props => {
  return (
    <ModalWrapper>
      <Modal {...props} />
    </ModalWrapper>
  );
};

export default ModalContainer;
