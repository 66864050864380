import styled, { css } from 'styled-components';

const BannerWrapper = styled.section`
  margin-top: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 85vh;
  background-color: ${({ theme }) => theme.colors.purple};
  ${({ cover }) =>
    cover &&
    css`
      background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
        url(${cover});
    `};

  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  ${'' /*
  @media (min-width: 1440px) {
    background-size: 130vh;
  }
  @media (max-width: 1440px) {
    background-size: 125vh;
  }
  @media (max-width: 1200px) {
    background-size: 115vh;
  }
  @media (max-width: 990px) {
    background-size: 100vh;
  } */}
  @media (max-width: 768px) {
    background-image: none;
  }

  ${'' /* @media only screen and (max-width: 480px) {
    padding-top: 130px;
  } */}

  .row {
    position: relative;
    z-index: 1;
  }
  .button__wrapper {
    margin-top: 40px;
    .reusecore__button {
      border-radius: 6px;
      &:first-child {
        transition: all 0.3s ease;
        margin-right: 30px;
        @media (max-width: 990px) {
          margin-right: 0;
        }
        ${'' /* &:hover {
          box-shadow: ${({ theme }) => theme.colors.primaryBoxShadow}
        } */}
      }
      &:nth-child(2) {
        padding-left: 0;
        @media (max-width: 1100px) {
          padding-left: 15px;
        }

        @media (max-width: 480px) {
          padding-left: 0;
          padding-top: 15px;
        }
        margin-top: 15px;
        .btn-text {
          font-weight: 700;
          margin-top: 7px;
        }
        .btn-icon {
          margin-top: 6px;
          margin-left: 6px;
        }
      }
      > a {
        font-weight: 700;
        color: #fff;
      }
    }
    .btnWithoutColor {
      margin-right: 15px !important;
      padding: 5px 0px !important;
      .btn-icon {
        svg {
          width: 30px;
          height: 30px;
        }
      }
      .btn-text {
        padding-left: 15px;
        font-size: 15px;
        font-weight: 500 !important;
        font-family: 'Poppins', sans-serif;
      }
      &:hover {
        box-shadow: none !important;
      }
    }
  }
  .osram-logo {
    margin-top: 20px;
    ${'' /* filter: drop-shadow(5px 5px 5px #222222); */}
    background-color: white;
    padding: 28px 32px;
  }
`;

export const BgImageWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  img {
    width: 100%;
    height: auto;
    display: block;
    margin: -2px -1px;
    @media (max-width: 480px) {
      margin: -2px -1px;
    }
  }
`;
export default BannerWrapper;
