import React from 'react';
import { Link } from 'gatsby';
import Button from 'reusecore/src/elements/Button';

import OsramCategoriesWrapper, {
  CategoryCardWrapper,
  GridContainer
} from './osramcategories.style';

const CategoryCard = ({ category }) => {
  const { cover, title, subtitle, category_slug } = category;
  return (
    <CategoryCardWrapper cover={cover}>
      <span>{subtitle}</span>
      <h3>{title}</h3>
      <Link to={`/osram/${category_slug}`}>
        <Button variant="hoverShadow" title="Revisar más" />
      </Link>
    </CategoryCardWrapper>
  );
};

const OsramCategories = ({ categories }) => {
  // console.log({ categories });
  return (
    <OsramCategoriesWrapper>
      <GridContainer>
        <CategoryCard category={categories[0]} />
      </GridContainer>
      <GridContainer>
        <CategoryCard category={categories[1]} />
        <CategoryCard category={categories[2]} />
      </GridContainer>
      <GridContainer>
        <CategoryCard category={categories[3]} />
      </GridContainer>
      <GridContainer>
        <CategoryCard category={categories[4]} />
        <CategoryCard category={categories[5]} />
      </GridContainer>
    </OsramCategoriesWrapper>
  );
};

export default OsramCategories;
