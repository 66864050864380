import styled, { css } from 'styled-components';

const OsramCategoriesWrapper = styled.div``;

export const GridContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 15px;
  @media only screen and (max-width: 667px) {
    display: block;
    margin-bottom: 15px;
  }
`;

export const CategoryCardWrapper = styled.div`
  ${({ cover }) =>
    cover &&
    css`
      background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.4),
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
        url(${cover});
    `};
  margin-bottom: 15px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 300px;
  border-radius: 4px;
  padding: 30px 40px;
  color: white;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media only screen and (min-width: 667px) {
    padding: 40px 50px;
  }

  h2,
  h3,
  h4 {
    margin-top: 1em;
    font-size: 1.825rem;
    color: #ffffff;
    line-height: 2.375rem;
    font-weight: 400;
    max-width: 280px;
  }
  span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  button {
    border-radius: 5px;
    font-size: 0.875rem;
    font-weight: 700;
    ${'' /* transition: all 0.1s ease-in-out 0s; */}
  }
`;

export default OsramCategoriesWrapper;
