import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/source/contexts/DrawerContext';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/Navbar';
import Footer from 'common/source/containers/Footer';
import Container from 'common/source/components/UI/Container';
import Banner from 'common/source/containers/BannerOsram';
import Categories from 'common/source/containers/OsramCategories';
import VideoModal from 'common/source/containers/OsramVideoModal';
import Modal from 'common/source/containers/Modal';

import SEO from '../../components/SEO';
import config from '../../../data/SiteConfig';

const OsramContentWrapper = styled.div`
  margin-bottom: 3rem;
  .osram-heading {
    text-align: center;
    padding: 2rem;
    h2 {
      font-size: 2.25rem;
      font-weight: 500;
      color: ${props => props.theme.colors.headingColor};
      line-height: 1.25;
      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }
  }
`;

class OsramPage extends React.Component {
  render() {
    const { data } = this.props;
    const { page } = data;
    const { html, frontmatter } = page;
    // console.log({ page });
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO
            pageTitle={`${frontmatter.title} | ${config.siteTitle}`}
            path={`/${frontmatter.slug}`}
            pageNode={page}
          />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar background="purple" />
              </DrawerProvider>
            </Sticky>
            <Banner cover={frontmatter.cover} heading={frontmatter.title}>
              {frontmatter.subtitle}
            </Banner>
            <Container>
              <OsramContentWrapper>
                <div className="osram-heading">
                  <h2>Aplicaciones profesionales e industriales</h2>
                </div>
                <Categories categories={frontmatter.categories} />
                <VideoModal />
                <Modal />
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </OsramContentWrapper>
            </Container>
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default OsramPage;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query OsramQuery {
    page: markdownRemark(frontmatter: { slug: { eq: "osram" } }) {
      html
      timeToRead
      excerpt
      fields {
        slug
        date
      }
      frontmatter {
        slug
        title
        subtitle
        description
        type
        cover
        thumbnail
        categories {
          category_slug
          title
          subtitle
          description
          featured
          cover
          thumbnail
        }
      }
    }
  }
`;
