import React from 'react';
import Icon from 'react-icons-kit';
import { play } from 'react-icons-kit/entypo/play';
import Image from 'reusecore/src/elements/Image';
import Button from 'reusecore/src/elements/Button';

import { openModal, closeModal } from '@redq/reuse-modal';

import VideoModalWrapper, { PlayButton, VideoWrapper } from './osramvideo.style';

// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={closeModal}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://www.youtube-nocookie.com/embed/TTel3s0Z2uo?rel=0&autoplay=0"
      frameBorder="0"
    />
  </VideoWrapper>
);

const OsramVideoModal = () => {
  // modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
        default: {
          width: '100%',
          height: '100%',
          x: 0,
          y: 0
        }
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true
    });
  };

  return (
    <VideoModalWrapper>
      <Image
        src="https://media.osram.info/media/img/osram-dam-468876/s,x,1260,y,0/642700_592354_333384_337365_Visual_Spez_Beleuchtung_672x260_mb.jpg"
        alt="banner image"
      />
      <PlayButton onClick={handleVideoModal}>
        <Icon icon={play} size={40} />
      </PlayButton>
    </VideoModalWrapper>
  );
};

export default OsramVideoModal;
