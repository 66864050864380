import styled from 'styled-components';

const ModalWrapper = styled.div`
  .reuseModalOverlay {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    position: fixed;
  }

  .reuseModalParentWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 99999 !important;
    position: fixed;
    top: 0;
    left: 0;
  }

  .reuseModalHolder {
    width: 100%;
    height: 100%;
    display: flex;
    ${'' /* border: 1px solid #ddd;
    background: #f0f0f0; */}
    padding: 30px;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 99999;
    justify-content: center;
    align-items: center;

    max-width: 1054px;
    max-height: 656px;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 20px;
    top: 30px;
    transform: scale(1);
    .video-modal {
      background: transparent !important;
      border-width: 0px !important;
      border-style: initial !important;
      border-color: initial !important;
      border-image: initial !important;
    }
  }

  .innerRndComponent {
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .reuseModalCloseBtn {
    position: fixed;
    top: 10px;
    right: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #ffffff;
    background-color: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    z-index: 9999999;
  }
`;

export default ModalWrapper;
